import { v4 as uuidv4 } from 'uuid';
import AutomobileImg from '../images/Automobile.jpg';
import ETLImg from '../images/ETL.png';
import Warehouse_inventoryImg from '../images/Warehouse_inventory.jpg';
import Gradient_descentImg from '../images/Gradient_descent.gif';
import TimeSeriesImg from '../images/TimeSeries.gif';
import Agro_Spray_SuitImg from '../images/Agro_Spray_Suit.png';
import Agriculture_ProjectImg from '../images/farmer.jpg';
import OCR_Img from '../images/embedding.gif';
import Sentiment_Analysis_Img from '../images/Sentiment_Analysis.gif'; 
import Object_DetectionImg from '../images/Screenshot3.png'; 
import ECG_Img from '../images/prediction_ecg.gif'; 

const projects = [
  {
    id: uuidv4(),
    name: "Electrocardiogram-Anomaly-Detection-RNN-Time-Series ",
    desc:
      '| This is an implementation of RNN based time-series anomaly detector, which consists of two-stage strategy of time-series prediction and anomaly score calculation for ECG Dataset using RNN and Time Series |',
    img: ECG_Img,
    link: 'https://github.com/immanuvelprathap/Electrocardiogram-Anomaly-Detection-RNN-Time-Series'
  },
  {
    id: uuidv4(),
    name: "Object_Detetection - Broccoli Plant- DroneFootage - AI/ML",
    desc:
      '| Analyzing a video footage over broccoli plantation with the aim of detecting the broccoli plant/crop to have an estimation of homogeneity in crop plants. | To make an estimate of homogeneity in cultivated plants, one would have to manually count thousands of plants with huge waste of time and the risk of error. Without considering the problem of photographing or checking plant by plant in order to verify the growth, if it is damaged or other parameters.| Everything can be done by writing software that leverages computer vision and AI in real-time. Just fly the drone over the cultivated field. |',
    img: Object_DetectionImg,
    link: 'https://github.com/immanuvelprathap/Custom_Object_Detetection-Broccoli_Plant-DroneFootage'
  },
  {
    id: uuidv4(),
    name: "LSTM - Sentiment Classification Analysis",
    desc:
      '| RNN Deep Learning Algorithm | Using using Tensorflow - Keras | Amazon Review Data (2018) Dataset - Cell Phones and Accessories review| This Project aims to explain the concepts of Natural Language Processing and how to build a model using LSTM (Long Short Term Memory), a deep learning algorithm for performing sentiment analysis | ',
    img: Sentiment_Analysis_Img,
    link: 'https://github.com/immanuvelprathap/LSTM-Sentiment-Analysis-AmazonReviews-Dataset'
  },
  {
    id: uuidv4(),
    name: "Optical Character Recognition(OCR) - KNN - Using Python",
    desc:
      '| KNN Algorithm | Using Python | MNIST is a dataset of images—consisting of a training set of 60,000 examples and a test set of 10,000 examples. Each example is a 28x28 grayscale image, associated with a label from 10 classes. | Fashion-MNIST is a dataset of  images — consisting of a training set of 60,000 examples and a test set of 10,000 examples. Also, to serve as a direct drop-in replacement for the original MNIST dataset for benchmarking machine learning algorithms. It shares the same image size and structure of training and testing splits| ',
    img: OCR_Img,
    link: 'https://github.com/immanuvelprathap/Optical-Character-Recognition-KNN-Python'
  },
  {
    id: uuidv4(),
    name: 'Warehouse Inventory K-Means Clustering',
    desc:
      '| Unsupervised Learning | KMeans Clustering Algorithm | generating clusters randomly (in clusters of 5) | Likelihood of the Product Family to fit the Best Cluster | How do we place the particular Product Family and choose the best cluster for it based on the changing in trends of "Low Frequency" to "High Frequency" | ',
    img: Warehouse_inventoryImg,
    link: 'https://github.com/immanuvelprathap/KMeans-Clustering-Unsupervised-ML-Projects'
  },
  {
    id: uuidv4(),
    name: 'ETL',
    desc:
      '| Lets say a company is facing problems with thier Sales | As a Data Analyst or Data Scientist what insights can you pull out from the Sales data? | SALES ANALYSIS OF A LARGE DTATSET USING ETL-MYSQL-POWER BI |',
    img: ETLImg,
    link: 'https://github.com/immanuvelprathap/ETL-Sales_Analysis_Report---MySQL-PowerBI'
  },
  {
    id: uuidv4(),
    name: "Time-Series-Analysis",
    desc:
      '| Time series is a sequence of observations recorded at regular time intervals | This project walks you through the process of analyzing the characteristics of a given time series in python | Time Series Analysis in the IPython File |',
    img: TimeSeriesImg,
    link: 'https://github.com/immanuvelprathap/Time-Series-Datasets-Analysis'
  },
  {
    id: uuidv4(),
    name: 'Automobile-dataset',
    desc:
      '| Implemented Data Wrangling and Data Acquisition to pull the Used Car Prices from the Automobile dataset from the web browser | executed Data Pre-Processing | created a Linear Regression Model in order to predict the most suitable Car Price |',
    img: AutomobileImg,
    link: 'https://github.com/immanuvelprathap/Automobile-Data'
  },
  {
    id: uuidv4(),
    name: "Gradient_descent Algorithm",
    desc:
      '| Gradirnt Descent Algorithm explained using | Python OOPS | COST-WEIGHT-BIAS | ',
    img: Gradient_descentImg,
    link: 'https://github.com/immanuvelprathap/DataStructures-Algorithms-ML-concepts-using-Python'
  },
  {
    id: uuidv4(),
    name: "Agriculture Project",
    desc:
      '| Baccalaureate Project | Dual-integrated System of a PVC suit along with custom-built sprayers ( Arm sprayers and Palm Sprayers) interconnected via Modern Irrigation Systems, replacing the non-autonomous strenuous Knapsack Spraying System, eventually the goal is to design and develop an agribusiness model which can run commercially in present-time | Combining the methods of Microsoft Farm Beats and developing and deploying ML and Neural Network models (ie.., Open CV, CNNs, RNNs etc.) to understand the real-time collected datasets from farm areas using drone mapping software | ',
    img: Agro_Spray_SuitImg,
    link: 'https://drive.google.com/file/d/158h0RbqeN4Mrw5YY08m_7iMTisaANuKo/view?usp=sharing'
  },
  {
    id: uuidv4(),
    name: "Agriculture - Dataset Analysis",
    desc:
      '| Predicting Crops Yield : A Machine Learning Approach. Problem: Predict crops yield for 10 of most consumed crops worldwide. |',
    img: Agriculture_ProjectImg,
    link: 'https://github.com/immanuvelprathap/Agriculture-Project'
  },

  
];

export default projects;