import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ProjectImg from '../assets/images/projectImg.png';

const PublicationsItemStyles = styled.div`
  .publicationsItem__img {
    width: 100%;
    height: 200px;
    border-radius: 12px;
    overflow: hidden;
    display: inline-block;
    border: 3px solid var(--orange);
    img {
      height: 100%;
    }
  }
  .publicationsItem__info {
    margin-top: 1rem;
    background-color: var(--deep-dark);
    padding: 1rem;
    border-radius: 12px;
  }
  .publicationsItem__title {
    font-size: 2.2rem;
    color: var(--orange);
  }
  .publicationsItem__desc {
    font-size: 1.6rem;
    font-family: 'RobotoMono Regular';
    margin-top: 1rem;
    color: var(--white);
  }
  @media only screen and (max-width: 768px) {
    .publicationsItem__img {
      height: 350px;
    }
  }
  .publicationsButton{
    display: inline-block;
    font-size: 1.8rem;
    text-decoration: underline;
    margin: 2rem 0;
    color: var(--White)
  }
`;

export default function PublicationsItem({
  img = ProjectImg,
  title = '"Publication Name"',
  desc = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  link
}) {
  return (
    <PublicationsItemStyles>
      <Link to="/publications" className="publicationsItem__img">
        <img src={img} alt="publications img" />
      </Link>
      <div className="publicationsItem__info">
        <Link to="#">
          <h3 className="publicationsItem__title">{title}</h3>
        </Link>
        <p className="publicationsItem__desc">{desc}</p>
        {link && <a className={'publicationsButton'} target="_blank" rel="noreferrer" href={link}>Open Paper Link</a>}
      </div>
    </PublicationsItemStyles>
  );
}