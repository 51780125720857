import React from 'react';
import Hero from "../components/Hero";
import About from "../components/About";
import Skills from '../components/Skills';
import ProjectSection from '../components/ProjectSection';
import PublicationsSection from '../components/PublicationsSection'
import Footer from '../components/Footer';
import ContactBanner from '../components/ContactBanner';

export default function Home() {
  return (
    <div>
      <Hero />
      <About />
      <Skills />
      <ProjectSection />
      <PublicationsSection />
      <ContactBanner />
      <Footer></Footer>
    </div>
  );
}