import { v4 as uuidv4 } from 'uuid';
import Agro_Spray_SuitImg from '../images/Agro_Spray_Suit.png';
import Object_DetectionImg from '../images/Screenshot3.png'; 

const publications = [
  {
    id: uuidv4(),
    name: "Agriculture Project Thesis ",
    desc:
      '| Baccalaureate Project Thesis | Dual-integrated System of a PVC suit along with custom-built sprayers ( Arm sprayers and Palm Sprayers) interconnected via Modern Irrigation Systems, replacing the non-autonomous strenuous Knapsack Spraying System, eventually the goal is to design and develop an agribusiness model which can run commercially in present-time | Combining the methods of Microsoft Farm Beats and developing and deploying ML and Neural Network models (ie.., Open CV, CNNs, RNNs etc.) to understand the real-time collected datasets from farm areas using drone mapping software | ',
    img: Agro_Spray_SuitImg,
    link: 'https://drive.google.com/file/d/158h0RbqeN4Mrw5YY08m_7iMTisaANuKo/view?usp=sharing'
  },
  {
   id: uuidv4(),
   name: "Object_Detetection - Broccoli Plant- DroneFootage - AI/ML",
   desc:
     '| Currently, Working on a Paper relating to this Project |',
   img: Object_DetectionImg,
   link: 'https://github.com/immanuvelprathap/Custom_Object_Detetection-Broccoli_Plant-DroneFootage'
  },
];

export default publications;